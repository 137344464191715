import Toolbar from "./toolbar/Toolbar";
import Navbar from "./navbar/Navbar";
import "./menu.scss"
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import clsx from "clsx";

const Menu = () => {

  const open = useSelector((state: RootState) => state.hamburger.open);

    return (
        <div className={clsx("menu", open && "active")}>
            <div className="overflow-hidden">
                <div className="overflow-content">
                    <Toolbar />
                    <hr />
                    <Navbar />
                </div>
            </div>
        </div>
    )

}

export default Menu;