import {lazy, Suspense} from "react";
import Spinner from "../../../components/spinner/Spinner";

const Leaderboards = lazy(() => import("../../../pages/public/Leaderboards"));

const LeaderboardsRoute = () => {
    return (
        <Suspense fallback={
            <Spinner className="g-col-12" />}>
            <Leaderboards />
        </Suspense>
    )
}

export default LeaderboardsRoute;