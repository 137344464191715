import {useEffect, useState, useRef, ReactElement} from "react";
import DropdownContent from "./dropdownContent/DropdownContent";
import "./dropdownButton.scss";
import Button from "../button/Button";

type DropdownButtonProps = {
    icon?: ReactElement;
    value?: ReactElement;
    items: ReactElement;
}

const DropdownButton = (props: DropdownButtonProps) => {

    const { icon, value, items } = props;

    const [open, setOpen] = useState<boolean>(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setOpen((open) => !open);
    };

    useEffect(() => {
        const handler = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        document.addEventListener("click", handler);

        return () => {
            document.removeEventListener("click", handler);
        };
    }, [dropdownRef]);

    return (
        <div ref={dropdownRef} className="dropdown">
            <Button
                icon={icon}
                className="skuska"
                onClick={toggleDropdown}
            >
                {value}
            </Button>
            {
                <DropdownContent open={open}>
                    {items}
                </DropdownContent>
            }
        </div>
    );
};

export default DropdownButton;