import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {IRegularHoursPeriod, ISpecialHoursPeriod, regularHoursType, specialHoursType} from "../types/OpeningHoursTypes";
import axios from "axios";
import specialHours from "../../app/blocks/specialHours/SpecialHours";
import {isDateInFuture, parseDate} from "../../support/helpers/time";
import {IAddress} from "../types/AddressType";

type stateType = {
    fetched: boolean;
    loading: boolean;
    phone: string | null;
    storefrontAddress: IAddress | null;
    regularHours: regularHoursType | null;
    specialHours: specialHoursType | null,
    errorMessage: string | undefined,
}

const initialState: stateType = {
    fetched: false,
    loading: false,
    phone: null,
    storefrontAddress: null,
    regularHours: null,
    specialHours: null,
    errorMessage: undefined,
}
export const fetchBusiness = createAsyncThunk('business/fetchBusiness', () => {
    return axios
        .get('https://php.motokarymartin.sk/opening-hours/?location=zilina')
        .then((response) => {
            return response.data
        });
});

const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBusiness.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchBusiness.fulfilled, (state, action) => {
            state.fetched = true;
            state.loading = false;

            const openingHours: IRegularHoursPeriod[] = [];
            let nextDay = new Date();
            for (let i = 0; i < 7; i++) {
                const dayPeriods: IRegularHoursPeriod[] = action.payload.regularHours?.periods.filter((period: IRegularHoursPeriod) => {
                    return period.openDay === nextDay.toLocaleDateString('en-US', {weekday: 'long'}).toUpperCase();
                });

                nextDay.setHours(nextDay.getHours() + 24);

                const firstPeriod = dayPeriods.at(0);
                if (Array.isArray(firstPeriod?.openTime)) {
                    dayPeriods.shift();
                }

                const lastPeriod = dayPeriods.at(-1);
                if (lastPeriod && !lastPeriod.closeTime.hours) {
                    const nextDayPeriods = action.payload.regularHours?.periods.filter((period: IRegularHoursPeriod) => {
                        return period.openDay === nextDay.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
                    });
                    lastPeriod.closeTime = nextDayPeriods.shift()?.closeTime;
                }

                openingHours.push(...dayPeriods);
            }



            state.regularHours = {periods: openingHours};
            state.specialHours = {
                specialHourPeriods: action.payload.specialHours.specialHourPeriods
                .filter((specialHour: ISpecialHoursPeriod) => isDateInFuture(parseDate(specialHour.startDate)))
            };
            state.phone = action.payload.phoneNumbers.primaryPhone;
            state.storefrontAddress = action.payload.storefrontAddress;
        })
        builder.addCase(fetchBusiness.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = action.error.message;
        })
    },
});

export default businessSlice.reducer;