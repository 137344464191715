import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import sk from "./locales/sk.i18n.json";
import en from "./locales/en.i18n.json";

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    fallbackLng: 'sk',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: en
        },
        sk: {
            translation: sk
        }
    }
})

export default i18n;