import {ForwardedRef, ReactElement} from "react";
import "./button.scss"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    activated?: boolean
    variant?: "outlined" | "filled" | "toned" | "text" | "elevated"
    icon?: ReactElement;
}

const Button = ({activated, icon, variant, children, className, ...props}: ButtonProps) => {

    const combinedClassName = `button ${className || ''}`.trim();
    const defaultVariant = variant || 'elevated'.trim()

    return (
        <button className={combinedClassName} {...props} data-variant={defaultVariant} data-active={activated}>
            <div className="backdrop">
                {!!icon && <span className="icon">{icon}</span>}
                {!!children && <span className="value">{children}</span>}
            </div>
        </button>
    )

}

export default Button;