import {configureStore} from "@reduxjs/toolkit";
import OpeningHoursSlice from "./slices/businessSlice";
import HolidaysSlice from "./slices/holidaysSlice";
import ReviewsSlice from "./slices/reviewsSlice";
import HamburgerSlice from "./slices/hamburgerSlice";

export const store = configureStore({
    reducer: {
        business: OpeningHoursSlice,
        holidays: HolidaysSlice,
        reviews: ReviewsSlice,
        hamburger: HamburgerSlice,
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;