import Language from "./language/Language";
import "./toolbar.scss"
import Socials from "./socials/Socials";

const Toolbar = () => {

    return (
        <div className="toolbar">
            <Socials />
            <Language />
        </div>
    )
}

export default Toolbar;