import {lazy, Suspense} from "react";
import Spinner from "../../../components/spinner/Spinner";

const PriceList = lazy(() => import("../../../pages/public/PriceList"));

const PriceListRoute = () => {
    return (
        <Suspense fallback={
            <Spinner className="g-col-12" />}>
            <PriceList />
        </Suspense>
    )
}

export default PriceListRoute;