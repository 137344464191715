import {ForwardedRef, forwardRef, ReactElement} from "react";
import "./dropdownContent.scss";

type DropdownContentProps = {
    children: ReactElement;
    open: boolean;
}

const DropdownContent = (props: DropdownContentProps) => {
    const { children, open } = props;
    return (
        <div
            className={`dropdown-content ${open ? "content-open" : null}`}
        >
            {children}
        </div>
    );
};

export default DropdownContent;