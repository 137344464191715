import "./socials.scss"
import Button from "../../../../../components/button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {useNavigate} from "react-router-dom";

const Socials = () => {

  const navigate = useNavigate();
  const facebook = "https://www.facebook.com/emotokary.zilina";
  const instagram = "https://www.instagram.com/emotokary.zilina/";

    return (
        <>
          <Button icon={<FontAwesomeIcon icon={faInstagram} />} onClick={() => window.open(instagram)} />
          <Button icon={<FontAwesomeIcon icon={faFacebookSquare} />} onClick={() => window.open(facebook)} />
        </>
    )
}

export default Socials;