import {lazy, Suspense} from "react";
import Spinner from "../../../components/spinner/Spinner";

const HomePage = lazy(() => import("../../../pages/public/HomePage"));

const HomePageRoute = () => {
    return (
        <Suspense fallback={<Spinner className="g-col-12" />}>
            <HomePage />
        </Suspense>
    )
}

export default HomePageRoute;