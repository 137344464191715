import {useTranslation} from "react-i18next";
import DropdownButton from "../../../../../components/dropdownButton/DropdownButton";
import DropdownItem from "../../../../../components/dropdownButton/DropdownItem/DropdownItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEarthEurope} from "@fortawesome/free-solid-svg-icons";
import "./language.scss";
import Button from "../../../../../components/button/Button";
import Flag from "react-flagkit";

interface ILanguages {
  nativeName: string;
  flagCode: string;
  code: string;
};
const languages: ILanguages[] = [
    { nativeName: "English", flagCode: "GB", code: 'en' },
    { nativeName: "Slovensky", flagCode: "SK", code: 'sk' }
];
const Language = () => {

    const {i18n} = useTranslation();

    const getItems = () =>
        <>
            {
                languages.map(lang =>
                    <Button key={lang.code}
                            onClick={() => i18n.changeLanguage(lang.code)}
                            icon={<Flag country={lang.flagCode} />}>
                        <span>{lang.nativeName}</span>
                    </Button>
                )
            }
        </>

    return (
        <div className="language-picker">
            <DropdownButton
                icon={<FontAwesomeIcon icon={faEarthEurope}/>}
                items={getItems()}
            />
        </div>
    )
}

export default Language;