import logoDark from "images/logo/dark.svg";
import "./logo.scss";

const Logo = () => {
    return (
        <div className="logo-wrapper">
            <img src={logoDark} alt="Logo" />
        </div>
    )
}

export default Logo;