import { createSlice } from "@reduxjs/toolkit";

type stateType = {
  open: boolean;
};

const initialState: stateType = {
  open: false,
};

const hamburgerSlice = createSlice({
  name: 'hamburger',
  initialState,
  reducers: {
    toggle: (state) => {
      state.open = !state.open;
    },
    close: (state) => {
      state.open = false;
    },
  },
});

export const { toggle, close } = hamburgerSlice.actions; // Export the action
export default hamburgerSlice.reducer;