import {lazy, Suspense} from "react";
import Spinner from "../../../components/spinner/Spinner";

const SignIn = lazy(() => import("../../../pages/public/SignIn"));

const SignInRoute = () => {
    return (
        <Suspense fallback={
            <Spinner className="g-col-12" />}>
            <SignIn />
        </Suspense>
    )
}

export default SignInRoute;