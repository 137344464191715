import {IDate, IPeriod, timeType} from "../../store/types/OpeningHoursTypes";
import {ReactElement} from "react";
import { DateTime } from "luxon";
import i18n from "../locale/i18n";

export type TDay = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
export const days: TDay[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const formatTime = (time: timeType): string => {
    const parsedTime = new Date();
    parsedTime.setHours(time.hours, !time.minutes ? 0 : time.minutes);
    const options: Intl.DateTimeFormatOptions = {
        minute: 'numeric',
        hour: 'numeric',
    };

    return parsedTime.toLocaleString(i18n.resolvedLanguage, options);
};

export const formatTimePeriod = (period: IPeriod, key: number): ReactElement => {

    if (period.closed) {
        return <span key={`closed${key}`} className="g-start-2 g-col-span-3 h6 d-flex align-items-center justify-content-end">{i18n.t("specialOpeningHours.closed")}</span>
    }

    if (Array.isArray(period.openTime) && Array.isArray(period.closeTime)) {
        return <span key={`nonstop${key}`} className="g-start-2 g-col-span-3 h6 d-flex align-items-center justify-content-end">{i18n.t("specialOpeningHours.nonstop")}</span>
    }

    return (
        <>
            <span key={`open${key}`} className="g-start-2 h6 d-flex align-items-center justify-content-end">{formatTime(period.openTime)}</span>
            <span key={`middle${key}`} className="h6 d-flex align-items-center">-</span>
            <span key={`close${key}`} className="h6 d-flex align-items-center justify-content-end">{formatTime(period.closeTime)}</span>
        </>
    );
};

export const parseDate = (date: IDate | undefined): Date => {
    if (!date) return new Date();
    const UTC = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}T00:00:00.000+02:00`;
    return new Date(UTC)
};

export const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };
    return date.toLocaleDateString(i18n.resolvedLanguage, options);
};

export const getNowInSlovakia = (): Date => {
    return DateTime.now().setZone("Europe/Bratislava").toJSDate();
    /*
    const now = new Date();
    const utc = now.getTime() + (now.getTimezoneOffset() * 60000);
    return new Date(utc + 3600000 * 2)
     */
}

export const areDatesSame = (firstDate: Date, secondDate: Date): boolean => {
    return firstDate.getFullYear() === secondDate.getFullYear()
        && firstDate.getMonth() === secondDate.getMonth()
        && firstDate.getDate() === secondDate.getDate()
}

export const isDateInFuture = (firstDate: Date): boolean => {
    const secondDate = getNowInSlovakia();
    return firstDate.getFullYear() > secondDate.getFullYear()
      || (firstDate.getFullYear() === secondDate.getFullYear() && firstDate.getMonth() > secondDate.getMonth())
      || (firstDate.getFullYear() === secondDate.getFullYear() && firstDate.getMonth() === secondDate.getMonth() && firstDate.getDate() > secondDate.getDate())
}