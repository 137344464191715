import {NavLink} from "react-router-dom";
import {PUBLIC_PATHS} from "../../../../../constants/paths";
import "./navbar.scss"
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {close} from "../../../../../store/slices/hamburgerSlice";

const Navbar = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    return (
        <div className="navbar">
            {Object.values(PUBLIC_PATHS).map((path, index) =>
                <NavLink
                    key={index}
                    onClick={() => dispatch(close())}
                    to={path.path}
                    className={({ isActive, isPending }) =>
                        `nav-link ${isPending ? "pending" : isActive ? "active" : ""}`}
                >
                    <div className="backdrop">
                        <span className="value">{t(path.name)}</span>
                    </div>
                </NavLink>
            )}
        </div>
    )
}

export default Navbar;