import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {IHoliday} from "../types/HolidaysType";

type stateType = {
    fetched: boolean;
    loading: boolean;
    holidays: IHoliday[] | null;
}

const initialState: stateType = {
    fetched: false,
    loading: false,
    holidays: null,
}
export const fetchHolidays = createAsyncThunk('holidays/fetchHolidays', () => {
    const year = new Date().getUTCFullYear();
    return axios
        .get(`https://date.nager.at/api/v3/PublicHolidays/${year}/SK`)
        .then((response) => response.data);
});

const holidaysSlice = createSlice({
    name: 'holidays',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchHolidays.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchHolidays.fulfilled, (state, action) => {
            state.fetched = true;
            state.loading = false;
            state.holidays = action.payload;
        })
        builder.addCase(fetchHolidays.rejected, (state, action) => {
            state.loading = false;
            console.error("During fetching of public holidays there was an error:", action.error);
        })
    },
});

export default holidaysSlice.reducer;