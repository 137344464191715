import {lazy, Suspense} from "react";
import Spinner from "../../../components/spinner/Spinner";

const Calendar = lazy(() => import("../../../pages/public/Calendar"));

const CalendarRoute = () => {
    return (
        <Suspense fallback={
            <Spinner className="g-col-12" />}>
            <Calendar />
        </Suspense>
    )
}

export default CalendarRoute;