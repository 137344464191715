import Logo from "./logo/Logo";
import "./header.scss"
import Menu from "./menu/Menu";
import Hamburger from "./menu/hamburger/hamburger";

const Header = () => {
    return (
        <header className="header">
          <Logo />
          <div className="wrapper">
              <Hamburger />
              <Menu />
            </div>
        </header>
    )
}

export default Header;