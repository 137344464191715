import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {IReview} from "../types/ReviewsType";

type stateType = {
    fetched: boolean;
    loading: boolean;
    reviews: IReview[] | null;
}

const initialState: stateType = {
    fetched: false,
    loading: false,
    reviews: null,
}
export const fetchReviews = createAsyncThunk('reviews/fetchReviews', () => {
    return axios
        .get(`https://php.motokarymartin.sk/reviews/?location=martin`)
        .then((response) => response.data);
});

const reviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchReviews.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchReviews.fulfilled, (state, action) => {
            state.fetched = true;
            state.loading = false;

            const reviews: IReview[] = action.payload;
            reviews.reduce((accumulator: IReview[], currentValue: IReview) => {
                if (currentValue.starRating === "FIVE") {
                    accumulator.push(currentValue);
                }
                return accumulator;
            }, [])

            state.reviews = reviews;
        })
        builder.addCase(fetchReviews.rejected, (state, action) => {
            state.loading = false;
            console.error("During fetching of public holidays there was an error:", action.error);
        })
    },
});

export default reviewsSlice.reducer;