import "./spinner.scss";
import clsx from "clsx";

interface ISpinnerProps {
    className?: string;
}

const Spinner = ({className}: ISpinnerProps) => {
    return (
        <div className={clsx('d-flex justify-content-center align-items-center spinner-wrapper', className)}>
            <div className="spinner"></div>
        </div>
    )
}

export default Spinner;