import React from 'react';
import './App.css';
import AppRoot from "./app/AppRoot";
import Header from "./app/blocks/menu/Header";

function App() {
  return (
      <>
        <Header />
        <div className="layout">
            <AppRoot />
        </div>
      </>
  );
}

export default App;
