import {lazy, Suspense} from "react";
import Spinner from "../../../components/spinner/Spinner";

const Voucher = lazy(() => import("../../../pages/public/Voucher"));

const VoucherRoute = () => {
    return (
        <Suspense fallback={
            <Spinner className="g-col-12" />}>
            <Voucher />
        </Suspense>
    )
}

export default VoucherRoute;